body{overflow-x:hidden;font-family:'Roboto Slab',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}

p{line-height:1.75}

a{color:#36689f}

a:hover{color:#36689f}

.text-primary{color:#36689f!important}

h1,h2,h3,h4,h5,h6{font-weight:700;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}

/* About */
.page-section{padding:100px 0}
 
 .page-section h2.section-heading{font-size:40px;margin-top:0;margin-bottom:15px}
 
 .page-section h3.section-subheading{font-size:16px;font-weight:400;font-style:italic;margin-bottom:75px;text-transform:none;font-family:'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 
 @media (min-width:768px){section{padding:150px 0}}::-moz-selection{background:#b77e88;text-shadow:none}::selection{background:#b77e88;text-shadow:none}img::-moz-selection{background:0 0}
 
 img::selection{background:0 0}
 img::-moz-selection{background:0 0}
 
 .btn{font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';font-weight:700}.btn-xl{font-size:18px;padding:20px 40px}
 .btn-primary:hover{
    background-color: #212529;
    border-color: #212529;
 }
 .btn-primary{background-color:#b77e88;border-color:#0000}.btn-primary:active,.btn-primary:focus,{background-color:#b77e88 !important;border-color:#b77e88!important;color:#0000}.btn-primary:active,.btn-primary:focus{-webkit-box-shadow:0 0 0 .2rem #b77e88!important;box-shadow:0 0 0 .2rem #b77e88!important}
 .img{
    height: 200;
    width: 30%;
 }

 /* Skills */
.skills-img{
  height: 100px;
}
 .progress-wrap h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Quicksand", Arial, sans-serif;
  margin-bottom: 10px; }
.progress-wrap{
  background: #dbdbdd;
  padding-top: 15px;
  padding-right: 100px;
  padding-bottom: 10px;
}
.progress {
  height: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #e7e6e6;
  overflow: visible; }
.narrow-content {
  padding: 0 2.5em; }
  @media screen and (max-width: 768px) {
    .narrow-content {
      padding: 0; } }
.progress-bar {
  background: #2c98f0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 12px;
  line-height: 1.2;
  color: #000;
  font-weight: 600;
  text-align: right;
  position: relative;
  overflow: visible;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px; }
.progress-bar:after {
    position: absolute;
    top: -2px;
    right: 0;
    width: 10px;
    height: 10px;
    content: '';
    background: #2c98f0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
.progress-bar span {
    position: absolute;
    top: -22px;
    right: 0; }
.progress-bar.color-1 {
    background: #2c98f0; }
.progress-bar.color-1:after {
      background: #2c98f0; }
.progress-bar.color-1 span {
      color: #2c98f0; }
.progress-bar.color-2 {
    background: #ec5453; }
.progress-bar.color-2:after {
      background: #ec5453; }
.progress-bar.color-2 span {
      color: #ec5453; }
.progress-bar.color-3 {
    background: #f9bf3f; }
.progress-bar.color-3:after {
      background: #f9bf3f; }
    .progress-bar.color-3 span {
      color: #f9bf3f; }
  .progress-bar.color-4 {
    background: #b10000; }
    .progress-bar.color-4:after {
      background: #c90000; }
    .progress-bar.color-4 span {
      color: #ff0000; }
  .progress-bar.color-5 {
    background: #2fa499; }
    .progress-bar.color-5:after {
      background: #2fa499; }
    .progress-bar.color-5 span {
      color: #2fa499; }
  .progress-bar.color-6 {
    background: #4054b2; }
    .progress-bar.color-6:after {
      background: #4054b2; }
    .progress-bar.color-6 span {
      color: #4054b2; }

.row-bottom-padded-lg {
  padding-bottom: 7em; }
  @media screen and (max-width: 768px) {
    .row-bottom-padded-lg {
      padding-bottom: 1em; } }

.row-bottom-padded-md {
  padding-bottom: 3em; }
  @media screen and (max-width: 768px) {
    .row-bottom-padded-md {
      padding-bottom: 1em; } }

.row-bottom-padded-sm {
  padding-bottom: 1em; }
  @media screen and (max-width: 768px) {
    .row-bottom-padded-sm {
      padding-bottom: 1em; } }

.row-pt-md {
  padding-top: 3em; }



/* Navbar & Intro */
 #mainNav{background-color:#a8a4a4; height: 75px;}
 #mainNav .navbar-toggler{font-size:12px;right:0;padding:13px;text-transform:uppercase;color:#fff;background-color:#b77e88;font-family:'Times New Roman', Times, serif}
 #mainNav .navbar-brand{color: #212529;font-family:'Times New Roman', Times, serif}
 #mainNav .navbar-brand.active,#mainNav .navbar-brand:active,#mainNav .navbar-brand:focus,#mainNav .navbar-brand:hover{color:#fff;font-family:'Times New Roman', Times, serif}
 #mainNav .navbar-nav .nav-item .nav-link{font-size:90%;font-weight:400;padding:.75em 0;letter-spacing:1px;color:#212529;font-family:'Times New Roman', Times, serif}
 #mainNav .navbar-nav .nav-item .nav-link.active,#mainNav .navbar-nav .nav-item .nav-link:hover{color:#fff;font-family:'Times New Roman', Times, seri}
 @media (min-width:992px){#mainNav{padding-top:25px;padding-bottom:25px;-webkit-transition:padding-top .3s,padding-bottom .3s;transition:padding-top .3s,padding-bottom .3s;border:none;background-color:#a8a4a4}#mainNav .navbar-brand{font-size:1.75em;-webkit-transition:all .3s;transition:all .3s}#mainNav .navbar-nav .nav-item .nav-link{padding:1.1em 1em!important}#mainNav.navbar-shrink{padding-top:0;padding-bottom:0;background-color:#212529}#mainNav.navbar-shrink .navbar-brand{font-size:1.25em;padding:12px 0}}
 header.masthead{text-align:center;color:#fff;background-image:url(./assets/img/header-bg.jpeg);background-repeat:no-repeat;background: cover;background-attachment:scroll;background-position:center center;background-size:cover}
 header.masthead .intro-text{padding-top:150px;padding-bottom:100px}header.masthead .intro-text .intro-lead-in{font-size:22px;font-style:italic;line-height:22px;margin-bottom:25px;font-family:'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}header.masthead .intro-text .intro-heading{font-size:50px;font-weight:700;line-height:50px;margin-bottom:25px;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}@media (min-width:768px){header.masthead .intro-text{padding-top:300px;padding-bottom:200px}header.masthead .intro-text .intro-lead-in{font-size:40px;font-style:italic;line-height:40px;margin-bottom:25px;font-family:'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}header.masthead .intro-text .intro-heading{font-size:75px;font-weight:700;line-height:75px;margin-bottom:50px;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}}.service-heading{margin:15px 0;text-transform:none}
 
 
 
 /* Portfolio */
 #portfolio .portfolio-item{right:0;margin:0 0 15px}
 #portfolio .portfolio-item .portfolio-link{position:relative;display:block;max-width:400px;margin:0 auto;cursor:pointer}#portfolio .portfolio-item .portfolio-link .portfolio-hover{position:absolute;width:100%;height:100%;-webkit-transition:all ease .5s;transition:all ease .5s;opacity:0;background:#b77e88}#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover{opacity:1}#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content{font-size:20px;position:absolute;top:50%;width:100%;height:20px;margin-top:-12px;text-align:center;color:#fff}#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i{margin-top:-12px}#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4{margin:0}#portfolio .portfolio-item .portfolio-caption{max-width:400px;margin:0 auto;padding:25px;text-align:center;background-color:#fff}#portfolio .portfolio-item .portfolio-caption h4{margin:0;text-transform:none}
 #portfolio .portfolio-item .portfolio-caption p{font-size:16px;font-style:italic;margin:0;font-family:'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}#portfolio *{z-index:2}@media (min-width:767px){#portfolio .portfolio-item{margin:0 0 30px}}.portfolio-modal .modal-dialog{margin:1rem;max-width:100vw}.portfolio-modal .modal-content{padding:100px 0;text-align:center}.portfolio-modal .modal-content h2{font-size:3em;margin-bottom:15px}.portfolio-modal .modal-content p{margin-bottom:30px}.portfolio-modal .modal-content p.item-intro{font-size:16px;font-style:italic;margin:20px 0 30px;font-family:'Droid Serif',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}.portfolio-modal .modal-content ul.list-inline{margin-top:0;margin-bottom:30px}.portfolio-modal .modal-content img{margin-bottom:30px}.portfolio-modal .modal-content button{cursor:pointer}.portfolio-modal .close-modal{position:absolute;top:25px;right:25px;width:75px;height:75px;cursor:pointer;background-color:transparent}.portfolio-modal .close-modal:hover{opacity:.3}.portfolio-modal .close-modal .lr{z-index:1051;width:1px;height:75px;margin-left:35px;-webkit-transform:rotate(45deg);transform:rotate(45deg);background-color:#212529}.portfolio-modal .close-modal .lr .rl{z-index:1052;width:1px;height:75px;-webkit-transform:rotate(90deg);transform:rotate(90deg);background-color:#212529}
 
 /* Testimonials */
body { background-color: #f2f2f2; }
#testimonials #flexslider-carousel {
  margin: 0 auto;
  width: 300px;
  height: 453px;
}
#testimonials .flex-next, .flex-prev { height: 46px !important; }
.quote {
  margin: 0;
  background: #eee;
  padding: 1em;
  border-radius: 1em;
}
.quote figcaption,
.quote blockquote {
  margin: 1em;
}

/* about */
 .language-about{margin-bottom:50px;text-align:center}
 .language-about img, .about-cover{width:215px;height:225px;border:5px solid rgba(0,0,0,.1)}
 .language-about h4{margin-top:25px;margin-bottom:0;text-transform:none}
 .language-about p{margin-top:0}
 

  /* Contact */
 section#contact{background-color:#212529;background-image:url(./assets/img/bg.jpeg);background-position:center}
 section#contact .section-heading{color:#fff}
 section#contact .form-group{margin-bottom:25px}
 section#contact .form-group input,section#contact .form-group textarea{padding:20px}
 section#contact .form-group input.form-control{height:auto}
 section#contact .form-group textarea.form-control{height:248px}
 section#contact .form-control:focus{border-color:#b77e88;-webkit-box-shadow:none;box-shadow:none}
 section#contact ::-webkit-input-placeholder{font-weight:700;color:#ced4da;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 section#contact :-moz-placeholder{font-weight:700;color:#ced4da;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 section#contact ::-moz-placeholder{font-weight:700;color:#ced4da;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 section#contact :-ms-input-placeholder{font-weight:700;color:#ced4da;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 
 /* Footer */
 .footer {
   padding-top: 48px;
   margin-bottom: 48px;
   color: #0000;
   font-size: 14px;
   text-align: center;
   position: relative;
}

footer a, footer a:visited { color: rgb(0, 0, 0); }
footer a:hover, footer a:focus { color: #b77e88; }
.footer_ {
  background-color: #dbdbdb;
  text-align: center;
  align-items: center;
}
/* copyright */
footer .copyright {
    margin: 0;
    padding-bottom: 20px;
    padding-left: 450px;
 }
footer .copyright li {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 24px;
}
.ie footer .copyright li {
   display: inline;
}
footer .copyright li:before {
    content: "\2022";
    padding-left: 10px;
    padding-right: 10px;
    color: #095153;
}
footer .copyright  li:first-child:before {
    display: none;
}

/* social links */
.footer_ .social-links {
   margin: 18px 0 30px 0;
   padding: 0;
   padding-left: 450px;
   font-size: 30px;
}
footer .social-links li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 42px;
    color: #F06000;
}

footer .social-links li:first-child { margin-left: 0; }
